const GrabMenuImage = function (parentTitle) {
    if (parentTitle === "Admissions") {
      return "/admissions.jpg"
    }
    if (parentTitle === "About Us") {
      return "/about-us.jpg"
    }
    if (parentTitle === "Curriculum") {
      return "/curriculum.jpg"
    }
    if (parentTitle === "Sixth Form") {
      return "/sixth-form.jpg"
    }
    if (parentTitle === "Student Support") {
      return "/student-support.jpg"
    }
    if (parentTitle === "Academy Life") {
      return "/academy-life.jpg"
    }
    if (parentTitle === "Send Us A Message") {
      return "/contact-us.jpg"
    }
    return false
}

export default GrabMenuImage;