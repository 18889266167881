import React from 'react';

import { navigate, Link } from 'gatsby';

import {
  InstantSearch,
  SearchBox,
  Hits,
  connectStateResults,
} from 'react-instantsearch-dom';

import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: '0EeHJePwuAwrYHOPDBYOe8yJatSjs30R', // Be sure to use the search-only-api-key
    nodes: [
      {
        host: 'xmilk24u7znrpdbwp-1.a1.typesense.net',
        port: '443',
        protocol: 'https',
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    queryBy: 'title',
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

function Hit(props) {
  return (
    <div>
      <Link
        to={
          props.hit.page_path
            ? props.hit.page_path
            : props.hit.parentSlug
            ? props.hit.parentSlug + props.hit.slug
            : props.hit.slug
        }
      >
        {props.hit.title}
      </Link>
    </div>
  );
}

const Results = connectStateResults(
  ({ searchState, searchResults, children }) =>
    searchResults &&
    searchResults.nbHits !== 0 &&
    searchState.query &&
    searchState.query !== '' ? (
      children
    ) : (
      <></>
    )
);

const TopNavComponent = (props) => {
  return (
    <div className="topNav">
      <ul className="topLinks">
        <li>
          <a
            href="https://www.parentpay.com/schools/our-product/"
            target="_blank"
            rel="external noopener noreferer"
            className="parentLink"
          >
            <span>Parentpay</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.durham.gov.uk/localoffer"
            target="_blank"
            rel="external noopener noreferer"
            className="parentLink"
          >
            <span>Local Offer</span>
          </a>
        </li>
      </ul>
      <ul>
        <li id="header_office">
          <a
            href="https://adfs.academiestrust.org.uk/adfs/ls/?wa=wsignin1.0&wtrealm=urn:federation:MicrosoftOnline"
            target="_blank"
            rel="external noopener noreferer"
          >
            <img
              src="/office-365.png"
              alt="Office 365"
              loading="lazy"
              width="115px"
            />
          </a>
        </li>
        <li id="header_ceop">
          <a
            href="https://ceop.police.uk/"
            target="_blank"
            rel="external noopener noreferer"
          >
            <img src="/ceop.png" alt="CEOP" loading="lazy" width="96px" />
          </a>
        </li>
        <li id="header_schoolcloud">
          <a
            href="https://consett.schoolcloud.co.uk"
            target="_blank"
            rel="external noopener noreferer"
          >
            <span>
              <img
                src="/schoolcloud-logo.svg"
                alt="SchoolCloud"
                loading="lazy"
                width="200px"
              />
            </span>
          </a>
        </li>
        <li id="header_weduc">
          <a href="https://weduc.com/" target="_blank" rel="external noopener">
            <span>
              <img
                src="/weduc-logo.png"
                alt="Weduc"
                loading="lazy"
                width="93px"
              />
            </span>
          </a>
        </li>
        <li id="header_teams">
          <a
            href="https://teams.microsoft.com/"
            target="_blank"
            rel="external noopener noreferer"
          >
            <img src="/teams.png" alt="Teams" loading="lazy" width="32px" />
          </a>
        </li>
        <li id="header_ncdat" className="ncdat-logo">
          <a
            href="https://www.ncdat.org.uk/"
            target="_blank"
            rel="external noopener noreferer"
          >
            <img
              src="/ncdat-logo.png"
              alt="ncdat"
              loading="lazy"
              width="97px"
            />
          </a>
        </li>
        <li className="search-top">
          <InstantSearch
            indexName="consett_pages_v1"
            searchClient={searchClient}
          >
            <SearchBox
              onSubmit={(event) => {
                event.preventDefault();
                navigate(`/search/?term=${event.target.value || ''}`);
              }}
            />
            <Results>
              <Hits hitComponent={Hit} />
            </Results>
          </InstantSearch>
        </li>
      </ul>
    </div>
  );
};

export default TopNavComponent;
